import Head from "next/head";
import { Heading, VStack, Center } from "@chakra-ui/layout";
import Layout from "@components/layouts";
import React from "react";
import FirebaseAuthFields from "@lib/firebase/FirebaseAuthFields";
import {
  withAuthUser,
  AuthAction,
  withAuthUserTokenSSR,
} from "next-firebase-auth";

function Login() {
  return (
    <>
      <Head>
        <title>Login - Fenske App</title>
      </Head>
      <Center
        margin="0 auto"
        marginTop="clamp(0px, 25vh, 325px)"
        flexDirection="column"
        width="fit-content"
        alignItems="center">
        <VStack alignItems="flex-start" width="100%">
          <Heading as="h1">Fenske App</Heading>
          <Heading size="md">Melde dich an!</Heading>
        </VStack>
        <FirebaseAuthFields />
      </Center>
    </>
  );
}

export default withAuthUser({
  whenAuthed: AuthAction.REDIRECT_TO_APP,
})(Login);

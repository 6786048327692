import React, { useEffect, useState } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";

export const uiConfig = {
  signInSuccessUrl: "/",
  signInFlow: "popup",
  signInOptions: [
    {
      provider: "apple.com",
      customParameters: {
        locale: "de",
      },
      requireDisplayName: true,
      fullLabel: "Mit Apple anmelden",
    },
    /* {
      provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      scopes: ["https://www.googleapis.com/auth/plus.login"],
      requireDisplayName: true,
    }, */
    {
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      // display Name wird zu spät übergeben Name muss irgendwie händisch eingegeben werden.
      requireDisplayName: true,
      fullLabel: "Mit E-Mail anmelden",
    },
  ],
};

export default function FirebaseAuthFields() {
  const [renderAuth, setRenderAuth] = useState(false);
  useEffect(() => {
    if (typeof window !== "undefined") {
      setRenderAuth(true);
    }
  }, []);

  return (
    <div>
      {renderAuth ? (
        <StyledFirebaseAuth
          uiConfig={uiConfig}
          firebaseAuth={firebase.auth()}
        />
      ) : null}
    </div>
  );
}
